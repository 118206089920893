import React from "react"
import { CssBaseline, ThemeProvider } from "@mui/material"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { DEFAULT, PRIMARY } from "./src/utils/themes"

export default ({ element }) => (
  <ThemeProvider theme={DEFAULT}>
    {element}
    <CssBaseline />
    <ToastContainer theme="dark" />
  </ThemeProvider>
)
